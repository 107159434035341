import React from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom"; 
import SoundUserList from "./List"; 

const mapStateToProps = state => ({ 
    ...state.producer,
});

const mapDispatchToProps = dispatch => ({});

class MibSoundeffect extends React.Component {
    render() {
        
        console.log("渲染");
        // const { match } = this.props;
        //const basepath = match.path === "/" ? "" : match.path;
        return (  
            <Switch>                  
                <Route  component={SoundUserList} />  
            </Switch>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MibSoundeffect);