import React from "react";
import { Table,Popconfirm,Modal,Button,Form,Input,DatePicker,Divider,InputNumber,Message} from "antd";
import agent from "agent"; 
import { connect } from "react-redux";
import moment from "moment"
import locale from 'antd/es/date-picker/locale/zh_CN';
import helper from "utils/helper";
 
//import PropTypes from "prop-types";
 
const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});  
const { Search }=Input;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};
const { RangePicker } = DatePicker;  
class List extends React.Component
{ 
    
    constructor(props) {
        super(props);
        this.state = {
            pageNum:1,  /*翻页查询*/
            pageSize:10,  /*分页查询*/
            activePage: 1,  /*默认显示一页*/
            selectedRowKeys: [],  // 这里配置默认勾选列
            loading: false,  /*antd*/
            selectedRow:[],
            timeShow:false,
            price:1,
            usershow:false,
            remark: '',
            data:[],
            userinfo:{}, 
            startTime:"",
            endTime:"",
            opstate:1 //当前操作状态 1-插入 2-修改
        };
        
        agent.MibSoundeffect.getMibUserList({startpage:1,size:100}).then(res=>{ 
            this.setState({data:res.body.result});
        });
        
        
        /* ,(retdata)=>{
            console.log("数据 "+JSON.stringify(retdata));
            this.setState({data:retdata.result});
        }); */
    }
    
    updateUser = (e) => {  
        console.log(e.id+"hello data"); 
        this.setState({
            usershow:true, 
            timeShow:true,
            remark: e.remark,
            startTime: e.startTime,
            endTime: e.endTime, 
            price: e.price,
            opstate:2,
            userinfo:{id:e.userId,email:e.email,phone:e.phone,price:e.price,
                remark:e.remark,create_at:e.create_at},
            se: [helper.showDate(new Date(e.startTime || '')), helper.showDate(new Date(e.endTime || ''))]
        })
    }
    
    searchUers=(username)=>
    {   
        const params = {
            page: 1,
            size: 10,
            orderby: "-id",
            username: username,
        };        
        agent.User.get(params).then(res=>{   
            if(res.body.users.length>0)
            {
                this.setState({
                    userinfo:JSON.parse(JSON.stringify(res.body.users))[0],
                    usershow:true
                });  
            }
        }); 
        
    }
    onChange(date, dateString) {
        this.setState({ startTime: new Date(dateString[0].replace(/-/g, "/")).getTime() });
        this.setState({ endTime: new Date(dateString[1].replace(/-/g, "/")).getTime() });
    }
    remarkChange=(value)=>{ 
        this.setState({ remark: value.target.value }); 
    }
    priceChange=(value)=>{
        this.setState({ price: value }); 
    }
    deleteUser(id){
        let params={id:id};
        agent.MibSoundeffect.delete(params).then(res=>{ 
            Message.info(res.body.message);
            agent.MibSoundeffect.getMibUserList({startpage:1,size:100}).then(res=>{ 
                this.setState({data:res.body.result});
            });
        });
    }
    
    render(){  
        
        let list;
        if (this.state.usershow) {
            list =
                (
                    <div className="ys">
                        <span>ID: </span>
                        {this.state.userinfo.id}
                        <Divider />
                        <span>昵称: </span>
                        {this.state.userinfo.nickname}
                        <Divider />
                        <span>Email: </span>
                        {this.state.userinfo.email}
                        <Divider />
                        <span>电话: </span>
                        {this.state.userinfo.phone}
                        <Divider />
                        <span>注册时间: </span>
                        {helper.showDate(this.state.userinfo.create_at)}
                        <Divider />
                        <span className='mar'>有效期: </span>
                        <RangePicker  onChange={this.onChange.bind(this)}  locale={locale} allowClear showTime={{
                            hideDisabledOptions: true,
                            defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('00:00:00', 'HH:mm:ss')],
                        }}
                        format="YYYY-MM-DD HH:mm:ss"
                        />
                        <Divider />
                        <span className='mar'>价格(元): </span> 
                        <InputNumber
                            style={{ width: '300px' }}
                            defaultValue={this.state.price/100}
                            min="0.01"
                            step="0.01"
                            size="large"
                            precision={2}
                            onChange={this.priceChange.bind(this)}
                        />
                        <Divider />
                        <span className='mar'>备注: </span>
                        <Input placeholder="备注" style={{ width: '300px' }} onChange={this.remarkChange.bind(this)} defaultValue={this.state.remark} />
                    </div>
                )
        }

        const showModal = () => {
            this.setState({timeShow:true,opstate:1});
        }; 
        const hideModal=()=>{this.setState({timeShow:false});};
        const insertMibUser=()=>{
            let params={userid:this.state.userinfo.id,
                starttime:this.state.startTime,
                endtime:this.state.endTime,
                remark:this.state.remark,
                price:this.state.price
            };
            if(this.state.opstate==1)
            {
                agent.MibSoundeffect.post(params).then(res=>{
                    Message.info(JSON.stringify(res.body.message));
                    this.setState({timeShow:false}); 
                });
            }
            if(this.state.opstate==2)
            {             
                agent.MibSoundeffect.update(params).then(res=>{ 
                    Message.info(JSON.stringify(res.body.message));
                    this.setState({timeShow:false});
                });
            }
            agent.MibSoundeffect.getMibUserList({startpage:1,size:100}).then(res=>{ 
                this.setState({data:res.body.result});
            });
        };
         
        
        const columns = [

            {
                title: "用户ID",
                key: "id",
                render: (record) => {
                    return (
                        <span>
                            {record.userId}
                        </span>
                    );
                },
            },
            {
                title: "用户邮箱",
                key: "email",
                render: (record) => {
                    return (
                        <span>
                            {record.email}
                        </span>
                    );
                },
            },
            {
                title: "价格",
                key: "price",
                render: (record) => {
                    return (
                        <span>
                            {record.price / 100}元
                        </span>
                    );
                },
            },
            {
                title: "开始时间",
                key: "start_time",
                render: (record) => {
                    return (
                        <span>
                            {helper.showDate(new Date(record.startTime))}
                        </span>
                    );
                },
            },
            {
                title: "结束时间",
                key: "end_time",
                render: (record) => {
                    return (
                        <span>
                            {helper.showDate(new Date(record.endTime))}
                        </span>
                    );
                },
            },
            {
                title: "电话",
                key: "phone",
                render: (record) => {
                    return (
                        <span>
                            {record.phone}
                        </span>
                    );
                },
            },
            {
                title: "用户名",
                key: "username",
                render: (record) => {
                    return (
                        <span>
                            {record.username}
                        </span>
                    );
                },
            },
            {
                title: "备注",
                key: "remark",
                render: (record) => {
                    return (
                        <span>
                            {record.remark}
                        </span>
                    );
                },
            },
            {
                title: "操作",
                key: "do",
                render: (record) => {
                    let colors = {
                        color: 'blue',
                        // textDecoration: 'underline',
                        cursor: 'pointer'
                    }
                    return (
                        <div>
                            <p style={colors} onClick={this.updateUser.bind(this, record)}> 修改</p>
                            <Popconfirm title="确认删除该用户?" onConfirm={() => this.deleteUser(record.id)}>
                                <p style={colors}> 删除</p>
                            </Popconfirm>
                        </div>
                    );
                },
            },
        ];
        
        return (
            <div>
                <Button
                    onClick={showModal}
                    type="primary"
                    style={{ marginBottom: 16 }}
                >
                    添加
                </Button>
                <Table
                    columns={columns}
                    rowKey={record => record.userid}
                    dataSource={this.state.data}
                    pagination={false}
                    loading={this.state.loading}
                    onChange={this.handleTableChange}
                />
              
                <Modal
                    title={"添加音效折扣用户"}
                    centered
                    destroyOnClose
                    visible={this.state.timeShow}
                    onOk={insertMibUser}
                    onCancel={hideModal}
                    maskClosable={false} 
                >
                    <Form onSubmit={this.handleSubmit}  > 
                        <Form.Item {...formItemLayout}
                            label="用户账户"
                            name="username"
                            rules={[{ required: true, message: '请输入用户账户' }]}>                            
                            <Search placeholder="请输入用户账户" size="default" enterButton="查询" 
                                onSearch={this.searchUers} />
                        </Form.Item>
                    </Form>
                    <div>
                        {list}
                    </div>
                </Modal>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(List);