import React from "react";
import PropTypes from "prop-types";

import { Layout, Menu, Icon } from "antd";
// import custom css
const { Sider } = Layout;
const { SubMenu } = Menu;
// <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>

const defaultOpenKeys = [
    // "admin", "producer", "purchase", "audio"
];

const Sidebar = ({ state, location, linkTo, currentAdmin }) => {
    let pathname = location.pathname.replace(/\/profile\/.*/, "/profile");
    let email = currentAdmin.email
    if (email.indexOf('admin') != -1) {
        return (
            <Sider trigger={null} collapsible collapsed={state.collapsed}>
                <div className="logo" />
                <Menu
                    theme="dark"
                    mode="inline"
                    defaultSelectedKeys={["/"]}
                    selectedKeys={[pathname]}
                    onClick={linkTo}
                    defaultOpenKeys={defaultOpenKeys}
                >
                    <Menu.Item key="/">
                        <Icon type="dashboard" />
                        <span>Dashboard</span>
                    </Menu.Item>
                    <Menu.Item key="/admin">
                        <Icon type="user" />
                        <span>管理员</span>
                    </Menu.Item>
                    <SubMenu
                        key="audio"
                        title={
                            <span>
                                <Icon type="video-camera" />
                                <span>音乐</span>
                            </span>
                        }
                    >
                        <Menu.Item key="/audio/list">
                            <Icon type="bars" />
                            <span>全部</span>
                        </Menu.Item>
                        <Menu.Item key="/audio/review">
                            <Icon type="solution" />
                            <span>待审核</span>
                        </Menu.Item>
                        <Menu.Item key="/audio/susume">
                            <Icon type="solution" />
                            <span>推荐管理</span>
                        </Menu.Item>
                        <Menu.Item key="/audio/playlist/info">
                            <Icon type="solution" />
                            <span>类别管理</span>
                        </Menu.Item>
                        <SubMenu
                            className="hidden"
                            key="audio/condition"
                            title={
                                <span>
                                    <span>条件分类设置</span>
                                </span>
                            }
                        >
                            <Menu.Item key="/audio/condition/area">
                                <Icon type="solution" />
                                <span>地区分类</span>
                            </Menu.Item>
                            <Menu.Item key="/audio/condition/duration">
                                <Icon type="solution" />
                                <span>时长分类</span>
                            </Menu.Item>
                        </SubMenu>
                    </SubMenu>
                    <Menu.Item key="/pricegroup">
                        <Icon type="money-collect" />
                        <span>价格管理</span>
                    </Menu.Item>
                    <SubMenu
                        key="producer"
                        title={
                            <span>
                                <Icon type="solution" />
                                <span>卖家管理</span>
                            </span>
                        }
                    >
                        <Menu.Item key="/producer/list">
                            <Icon type="bars" />
                            <span>全部</span>
                        </Menu.Item>
                        <Menu.Item key="/producer/review">
                            <Icon type="solution" />
                            <span>待审核</span>
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu
                        key="order"
                        title={
                            <span>
                                <Icon type="shopping-cart" />
                                <span>买家订单</span>
                            </span>
                        }
                    >
                        <Menu.Item key="/order/list">
                            <Icon type="bars" />
                            <span>进行中</span>
                        </Menu.Item>
                        <Menu.Item key="/order/history">
                            <Icon type="bars" />
                            <span>历史</span>
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu
                        key="purchase"
                        title={
                            <span>
                                <Icon type="pay-circle" />
                                <span>线下购买</span>
                            </span>
                        }
                    >
                        <Menu.Item key="/purchase/offline">
                            <Icon type="bars" />
                            <span>全部</span>
                        </Menu.Item>
                        <Menu.Item key="/purchase/review">
                            <Icon type="bars" />
                            <span>待审核</span>
                        </Menu.Item>
                    </SubMenu>
                    <Menu.Item key="/customized">
                        <Icon type="team" />
                        <span>懒人推荐</span>
                    </Menu.Item>
                    <Menu.Item key="/purchase/list">
                        <Icon type="bank" />
                        <span>交易记录</span>
                    </Menu.Item>
                    <SubMenu
                        key="withdraw"
                        title={
                            <span>
                                <Icon type="wallet" />
                                <span>结算</span>
                            </span>
                        }
                    >
                        <Menu.Item key="/withdraw/list">
                            <Icon type="bars" />
                            <span>全部</span>
                        </Menu.Item>
                        <Menu.Item key="/withdraw/review">
                            <Icon type="bars" />
                            <span>未支付</span>
                        </Menu.Item>
                    </SubMenu>
                    <Menu.Item key="/user">
                        <Icon type="team" />
                        <span>用户</span>
                    </Menu.Item>
                    <SubMenu
                        key="page/contactus"
                        title={
                            <span>
                                <Icon type="solution" />
                                <span>联系我们</span>
                            </span>
                        }
                    >
                        <Menu.Item key="/page/contactus/list">
                            <Icon type="bars" />
                            <span>全部</span>
                        </Menu.Item>
                        <Menu.Item key="/page/contactus/review">
                            <Icon type="solution" />
                            <span>未查看</span>
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu
                        key="memberset"
                        title={
                            <span>
                                <Icon type="wallet" />
                                <span>优惠设置</span>
                            </span>
                        }
                    >
                        <Menu.Item key="/memberset/users">
                            <Icon type="bars" />
                            <span>会员优惠账号</span>
                        </Menu.Item>
                        <Menu.Item key="/memberset/codes">
                            <Icon type="bars" />
                            <span>优惠码</span>
                        </Menu.Item>
                        <Menu.Item key="/unmemberset/users">
                            <Icon type="bars" />
                            <span>非会员优惠账号</span>
                        </Menu.Item> 
                        <Menu.Item key="/specialseller/sellerlist">
                            <Icon type="bars" />
                            <span>特殊卖家设置</span>
                        </Menu.Item> 
                        <Menu.Item key="/mibsoundeffect/mibuserlist">
                            <Icon type="bars" />
                            <span>音效优惠账号</span>
                        </Menu.Item> 
                    </SubMenu>
                    <Menu.Item key="/page/partner/list">
                        <Icon type="team" />
                        <span>合作商</span>
                    </Menu.Item>

                    <Menu.Item key="/page/qa/list">
                        <Icon type="question" />
                        <span>帮助管理</span>
                    </Menu.Item>
                    <Menu.Item key="/newsmanage/news/list">
                        <Icon type="solution" />
                        <span>新闻管理</span>
                    </Menu.Item>
                    <Menu.Item key="/captcha">
                        <Icon type="safety-certificate" />
                        <span>验证码</span>
                    </Menu.Item>

                    <Menu.Item key="/member">
                        <Icon type="sketch" />
                        <span>订阅会员</span>
                    </Menu.Item>

                    <SubMenu
                        key="cooperation"
                        title={
                            <span>
                                <Icon type="solution" />
                                <span>第三方合作管理</span>
                            </span>
                        }
                    >
                        <Menu.Item key="/cooperation/online">
                            <Icon type="bars" />
                            <span>线上管理</span>
                        </Menu.Item>
                        <Menu.Item key="/cooperation/offline">
                            <Icon type="solution" />
                            <span>线下管理</span>
                        </Menu.Item>
                    </SubMenu>
                    
                    <SubMenu
                        key="customerdata"
                        title={
                            <span>
                                <Icon type="solution" />
                                <span>营销数据</span>
                            </span>
                        }> 
                        <Menu.Item key="/customerdata/usersource">
                            <Icon type="bars" />
                            <span>用户来源</span>
                        </Menu.Item> 
                        <Menu.Item key="/search/searchkeyword">
                            <Icon type="bars" />
                            <span>用户搜索关键词</span>
                        </Menu.Item> 
                        
                    </SubMenu>
                </Menu>
            </Sider>
        );

    } else if (email.indexOf('music') != -1) {
        if(email=="ergeng@music.com")
            return (
                <Sider trigger={null} collapsible collapsed={state.collapsed}>
                    <div className="logo" />
                    <Menu
                        theme="dark"
                        mode="inline"
                        defaultSelectedKeys={["/"]}
                        selectedKeys={[pathname]}
                        onClick={linkTo}
                        defaultOpenKeys={defaultOpenKeys}
                    >
                        <SubMenu
                            key="audio"
                            title={
                                <span>
                                    <Icon type="video-camera" />
                                    <span>音乐</span>
                                </span>
                            }
                        >
                            <Menu.Item key="/audio/list">
                                <Icon type="bars" />
                                <span>全部</span>
                            </Menu.Item>
                        </SubMenu>
                    </Menu>
                </Sider>
            )
        else
            return(
                <Sider trigger={null} collapsible collapsed={state.collapsed}>
                    <div className="logo" />
                    <Menu
                        theme="dark"
                        mode="inline"
                        defaultSelectedKeys={["/"]}
                        selectedKeys={[pathname]}
                        onClick={linkTo}
                        defaultOpenKeys={defaultOpenKeys}
                    >
                        <SubMenu
                            key="audio"
                            title={
                                <span>
                                    <Icon type="video-camera" />
                                    <span>音乐</span>
                                </span>
                            }
                        >
                            <Menu.Item key="/audio/list">
                                <Icon type="bars" />
                                <span>全部</span>
                            </Menu.Item>
                            <Menu.Item key="/audio/review">
                                <Icon type="solution" />
                                <span>待审核</span>
                            </Menu.Item>
                            <Menu.Item key="/audio/susume">
                                <Icon type="solution" />
                                <span>推荐管理</span>
                            </Menu.Item>
                            <Menu.Item key="/audio/playlist/info">
                                <Icon type="solution" />
                                <span>类别管理</span>
                            </Menu.Item>
                        </SubMenu>
                    </Menu>
                </Sider>
            )
    } else if (email.indexOf('marketing') != -1) {
      
        return (
            <Sider trigger={null} collapsible collapsed={state.collapsed}>
                <div className="logo" />
                <Menu
                    theme="dark"
                    mode="inline"
                    defaultSelectedKeys={["/"]}
                    selectedKeys={[pathname]}
                    onClick={linkTo}
                    defaultOpenKeys={defaultOpenKeys}
                >
                    <SubMenu
                        key="page/contactus"
                        title={
                            <span>
                                <Icon type="solution" />
                                <span>联系我们</span>
                            </span>
                        }
                    >
                        <Menu.Item key="/page/contactus/list">
                            <Icon type="bars" />
                            <span>全部</span>
                        </Menu.Item>
                        <Menu.Item key="/page/contactus/review">
                            <Icon type="solution" />
                            <span>未查看</span>
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu
                        key="memberset"
                        title={
                            <span>
                                <Icon type="wallet" />
                                <span>优惠设置</span>
                            </span>
                        }
                    >
                        <Menu.Item key="/memberset/users">
                            <Icon type="bars" />
                            <span>优惠账号</span>
                        </Menu.Item>
                        <Menu.Item key="/memberset/codes">
                            <Icon type="bars" />
                            <span>优惠码</span>
                        </Menu.Item>
                        
                    </SubMenu>
                    <Menu.Item key="/page/partner/list">
                        <Icon type="team" />
                        <span>合作商</span>
                    </Menu.Item>
                    <Menu.Item key="/page/qa/list">
                        <Icon type="question" />
                        <span>帮助管理</span>
                    </Menu.Item>
                    <Menu.Item key="/newsmanage/news/list">
                        <Icon type="solution" />
                        <span>新闻管理</span>
                    </Menu.Item>
                </Menu>
            </Sider>
        );
    }else{
        return (
            <Sider trigger={null} collapsible collapsed={state.collapsed}>
          
      
            </Sider>
        )
    }

};

Sidebar.propTypes = {
    state: PropTypes.object.isRequired,
    linkTo: PropTypes.func.isRequired,
    currentAdmin: PropTypes.object.isRequired,
};

export default Sidebar;
